import React from 'react';
// import Footer from '../components/Footer';
import Layout from '../components/Layout';
import Nav from '../components/Nav';
import styled from 'styled-components';
const HomePageStyles = styled.div`
  font-size: var(--paragraphFontSize);
  margin-left: 2vw;
  margin-right: 2vw;
  max-width: 450px;
  margin-top: var(--headerHeight);
  // text-align: center;
  h1 {
    // text-align: center;
  }
`;
export default function HomePage() {
  return (
    <HomePageStyles>
    <h1>
      Our Business
    </h1>
    {/* make customers below dynamic, make a component that will cycle through an array of synonyms ie clients, visitors, customers, etc */}
    <div>Traterna is dedicated to making life easier for YOUR customers. Up to date and aesthetically pleasing web presence is key to building trust.</div>
    </HomePageStyles>
  )
}
